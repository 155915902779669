import '@theme/assets/styles/legacy.scss';
import '@theme/assets/styles/main.scss';

import { production } from '@theme/assets/scripts/utils.js';
import Stage from '@theme/assets/scripts/stage.js';

/*
 * Observers
 */

/* Services (Home) */

const servicesIn = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add('show-tab');
        } else {
            entry.target.classList.remove('show-tab');
        }
    });
});

const servicesOut = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (!entry.isIntersecting) {
            entry.target.parentElement.parentElement.classList.remove('show-tab');
        }
    });
});

/*
 * Run
 */

// On full page load
window.addEventListener('load', function (event) {
    // Mobile Menu
    this.document.getElementById('menu-icon').addEventListener('click', function () {
        this.classList.toggle('change');
    });

    // Hero Animation
    if (document.querySelector('canvas.webgl')) {
        new Stage();
    }

    if (document.querySelector('.services-row')) {
        servicesIn.observe(document.querySelector('.services-row:first-child'));
        servicesIn.observe(document.querySelector('.services-row:last-child'));

        servicesOut.observe(document.querySelector('.services-inclusions > :first-child'));
        servicesOut.observe(document.querySelector('.services-addons > :first-child'));
    }

    // Form Handlers
    gravityformHook();
});

/*
Gravity Forms
*/

function gravityformHook() {
    const target = '.gravity-form';

    if ($(target).length > 0) {
        $('.gform_button').removeAttr('onclick');

        $('.gravity-form form').on('submit', function (event) {
            const endpointUrl = $(this).parents(target).attr('data-gf-url').replace('=', '');
            const formId = $(this).parents(target).attr('data-gf-id');
            const baseUrl = $(this).parents(target).attr('data-gf-baseurl').replace('=', '');

            !production() && console.log('Endpoint:', endpointUrl);

            event.preventDefault();

            // Get all data
            const data = {};
            const values = {};
            $.each($(this).serializeArray(), function (i, field) {
                if (field.name.startsWith('input_')) {
                    values[field.name] = field.value;
                } else if (field.name === 'g-recaptcha-response') {
                    values[field.name] = field.value;
                }
            });
            data.baseUrl = baseUrl;
            data.payload = values;

            !production() && console.log('Request:', { data });

            const json = JSON.stringify(data);

            var currentForm = $(this);

            currentForm.find('.gform_button').removeAttr('onclick');
            // .removeEventListener('click', gform.submission.handleButtonClick);

            $.ajax({
                method: 'POST',
                url: endpointUrl,
                data: json,
                contentType: 'application/json',
                beforeSend: function () {
                    // Show spinner
                    $(currentForm).find('.gform_button').attr('disabled', 'disabled');
                    $(currentForm).parents(target).addClass('loading');
                    $(currentForm).parents(target).find('.form-messages').html('');
                },
                success: function (data) {
                    !production() && console.log('Response:', { data });

                    const result = data;

                    // Successful
                    if (result.status === 'success') {
                        if (formId === '1') {
                            window.location.href = '/thank-you';
                        } else {
                            window.location.href = '/thank-you-for-your-enquiry';
                        }
                        // if (result.confirmation_message) {
                        //     $(currentForm)
                        //         .parents(target)
                        //         .find('.form-messages')
                        //         .html(result.confirmation_message);
                        //     $(currentForm)[0].reset();
                        //     // $(currentForm).find('.gform_button').removeAttr('disabled');
                        //     $(currentForm).find('.gform_button').css('display', 'none');
                        //     $(currentForm).parents(target).removeClass('loading');
                        //     setTimeout(function () {
                        //         $(currentForm).parents(target).find('.form-messages').html('');
                        //     }, 30000);
                        //     return;
                        // }

                        // Errors
                    } else {
                        var messages = $(currentForm).parents(target).find('.form-messages');
                        messages.html(
                            '<div class="error">Your submission contained the following errors: <ul class="errors"></ul></div>'
                        );

                        $.each(result.validation_messages, function (i, e) {
                            !production() && console.log('Error:', { i, e });
                            const currentField = $(currentForm).find('#field_' + formId + '_' + i);
                            $(currentField).addClass('invalid');
                            messages.find('.errors').append('<li>' + e + '</li>');
                        });

                        $(currentForm).find('.gform_button').removeAttr('disabled');
                        $(currentForm).parents(target).removeClass('loading');

                        if (grecaptcha !== undefined) {
                            grecaptcha.reset();
                        }

                        // Go to top of form
                        scrollToAnchor('form-' + formId);
                    }
                },
                error: function (xhr, status, error) {
                    !production() && console.log('Response:', { xhr, status, error });

                    $(currentForm)
                        .parents(target)
                        .find('.form-messages')
                        .html(
                            '<div class="error-message">There was an error sending message. Please try again later.</div>'
                        );
                    $(currentForm).find('.gform_button').attr('disabled', 'false');
                    $(currentForm).find('.gform_button').removeAttr('disabled');
                    $(currentForm).parents('.blk_form').removeClass('loading');

                    setTimeout(function () {
                        $(currentForm).parents(target).find('.form-messages').html('');
                    }, 30000);
                }
            });

            return true;
        });
    }
}

/*
 * Misc
 */

function scrollToAnchor(id) {
    var loc = $("[id='" + id + "']");
    $('html,body').animate({ scrollTop: loc.offset().top }, 'slow');
}
