import * as THREE from 'three';
// import GSAP from 'gsap';

export default class {
    /*
     * Initialize
     */

    constructor() {
        this.canvas = document.querySelector('canvas.webgl');

        this.viewport = {
            // width: window.innerWidth,
            // height: window.innerHeight
            width: this.canvas.offsetWidth,
            height: this.canvas.offsetHeight
        };

        this.orientation = window.innerWidth < window.innerHeight ? 'portrait' : 'landscape';

        this.mouse = {
            x: 0,
            y: 0
        };

        this.params = {};

        this.scene = new THREE.Scene();

        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
            alpha: true,
            precision: 'lowp'
        });

        this.clock = new THREE.Clock();

        this.update = this.update.bind(this);

        this.init();
    }

    init() {
        this.addCameras();
        this.addMesh();

        this.addEventListeners();
        this.resize();
        this.update();
    }

    /*
     * Render
     */

    render() {
        this.renderer.render(this.scene, this.camera);
    }

    update() {
        const elapsedTime = this.clock.getElapsedTime();

        // Rotate Mesh
        this.globe.rotation.x = -elapsedTime * 0.005;
        this.globe.rotation.y = elapsedTime * 0.005;

        // Rotate Nodes
        const positions = this.globe.geometry.getAttribute('position');

        const vertex = new THREE.Vector3();
        const look = new THREE.Vector3();
        const target = new THREE.Vector3();

        // console.log(positions.count);

        for (let i = 0; i < this.vertices.length; i++) {
            vertex.fromBufferAttribute(positions, this.vertices[i]);

            this.nodes[i].position.copy(this.globe.localToWorld(vertex));

            look.subVectors(this.nodes[i].position, this.globe.position).normalize();
            target.copy(this.nodes[i].position).add(look);

            this.nodes[i].lookAt(target);
        }

        this.render();

        window.requestAnimationFrame(this.update);
    }

    /*
     * Elements
     */

    addCameras() {
        // Primary Camera
        this.camera = new THREE.PerspectiveCamera(
            50,
            this.viewport.width / this.viewport.height,
            0.1,
            5
        );
        this.camera.position.set(-0.6, 0.7, 3);

        this.scene.add(this.camera);
    }

    addMesh() {
        const globeGeometry = new THREE.IcosahedronGeometry(1.5, 24);

        const globeMaterial = new THREE.MeshBasicMaterial({
            color: 0x122b46,
            // wireframe: true,
            visible: false
        });

        this.globe = new THREE.Mesh(globeGeometry, globeMaterial);
        this.scene.add(this.globe);

        const matA = new THREE.MeshBasicMaterial({
            color: 0x65b4e4
        });
        const matB = new THREE.MeshBasicMaterial({
            color: 0x233984
        });
        const matC = new THREE.MeshBasicMaterial({
            color: 0x53b4b8
        });
        const matD = new THREE.MeshBasicMaterial({
            color: 0x9ab9d4
        });
        const matE = new THREE.MeshBasicMaterial({
            color: 0x5782c0
        });

        const colors = [matA, matB, matC, matD, matE, matA, matD, matC, matD, matE];

        const positions = globeGeometry.getAttribute('position');

        const vertex = new THREE.Vector3();
        const look = new THREE.Vector3();
        const target = new THREE.Vector3();

        this.vertices = [];
        this.nodes = [];
        for (let i = 0; i < positions.count; i++) {
            if (Math.random() * 10 > 9) {
                let factor = (Math.random() * 10).toFixed();

                this.vertices.push(i);

                vertex.fromBufferAttribute(positions, i);

                const nodeGeometry = new THREE.Mesh(
                    new THREE.CircleGeometry(0.02, 12),
                    colors[factor]
                );

                nodeGeometry.position.copy(vertex);

                look.subVectors(nodeGeometry.position, this.globe.position).normalize();
                target.copy(nodeGeometry.position).add(look);

                nodeGeometry.lookAt(target);

                this.scene.add(nodeGeometry);
                this.nodes.push(nodeGeometry);
            }
        }
    }

    /*
     * Events
     */

    addEventListeners() {
        window.addEventListener('resize', this.resize.bind(this));
        window.addEventListener('mousemove', this.move.bind(this));
    }

    resize() {
        let viewport = document.querySelector('.hero-banner > .background');

        this.viewport = {
            // width: window.innerWidth,
            // height: window.innerHeight
            width: viewport.offsetWidth,
            height: viewport.offsetHeight
        };

        if (this.orientation === 'portrait') {
            // this.globe.scale.set(0, 0, 0);
        }

        this.camera.aspect = this.viewport.width / this.viewport.height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(this.viewport.width, this.viewport.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.5));
    }

    move(e) {
        this.mouse.x = (e.clientX / this.viewport.width).toFixed(2);
        this.mouse.y = (e.clientY / this.viewport.height).toFixed(2);

        const factorX = this.mouse.x * 2.4 + 1;
        const factorY = this.mouse.y * 1.2 + 0.7;

        // GSAP.to(this.mesh.material.uniforms.uFrequency, { value: factorX });
        // GSAP.to(this.mesh.material.uniforms.uAmplitude, { value: factorX });
        // GSAP.to(this.mesh.material.uniforms.uDensity, { value: factorY });
        // GSAP.to(this.mesh.material.uniforms.uStrength, { value: factorY });
        // GSAP.to(this.mesh.material.uniforms.uDeepPurple, { value: this.mouse.x });
        // GSAP.to(this.mesh.material.uniforms.uOpacity, { value: this.mouse.y });
    }
}
